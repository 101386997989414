import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TAX_RATES = {
  AB: { gst: 0.05, pst: 0 }, // Alberta
  BC: { gst: 0.05, pst: 0.07 }, // British Columbia
  MB: { gst: 0.05, pst: 0.07 }, // Manitoba
  NB: { gst: 0.15, pst: 0 }, // New Brunswick
  NL: { gst: 0.15, pst: 0 }, // Newfoundland and Labrador
  NS: { gst: 0.15, pst: 0 }, // Nova Scotia
  ON: { gst: 0.13, pst: 0 }, // Ontario
  PE: { gst: 0.15, pst: 0 }, // Prince Edward Island
  QC: { gst: 0.05, pst: 0.09975 }, // Quebec
  SK: { gst: 0.05, pst: 0.06 }, // Saskatchewan
};

const PROMO_CODE = 'SAVE10';

const ReviewOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData } = location.state || {};

  const [promoCode, setPromoCode] = useState(''); // Holds the promo code input
  const [promoError, setPromoError] = useState(''); // Holds error message for invalid promo code
  const [promoDiscount, setPromoDiscount] = useState(0); // Stores the discount applied by the promo code
  const [showOverlay, setShowOverlay] = useState(false); // Controls visibility of the thank you overlay

  // Calculate the order summary
  const calculateOrderSummary = () => {
    let subtotal = 0;
    let shipping = formData.deliveryShipping === 'Delivery + Shipping' ? 20 : 0;

    formData.items.forEach((item) => {
      const { material, quantity } = item;

      switch (material) {
        case 'VHS':
        case 'VHSc':
        case 'HDV':
        case 'MiniDV':
        case 'Hi8':
        case 'Digital 8':
        case '8MM':
        case 'DV':
        case 'DVcam':
          subtotal += quantity * 20;
          break;
        case 'DVD':
          subtotal += quantity * 15;
          break;
        case 'Beta':
          subtotal += quantity * 40;
          break;
        case '8mm film':
        case 'Super8 film':
          subtotal += quantity * 0.25;
          break;
        case '16mm film':
          subtotal += quantity * 1;
          break;
        case 'slides':
          subtotal += quantity * 0.75;
          break;
        case '35mm negatives':
          subtotal += quantity * 1;
          break;
        case '35mm roll negatives':
          subtotal += quantity * 7.95;
          break;
        case 'photos':
          subtotal += quantity >= 2 ? quantity * 17.5 : quantity * 20;
          break;
        case 'photo album pages':
          subtotal += quantity * 2;
          break;
        default:
          break;
      }
    });

    const taxRates = TAX_RATES[formData.province] || { gst: 0, pst: 0 };
    const tax = subtotal * (taxRates.gst + taxRates.pst);
    const totalBeforeDiscount = subtotal + tax + shipping;

    const totalAfterDiscount = totalBeforeDiscount - promoDiscount;

    return {
      subtotal: subtotal.toFixed(2),
      promoDiscount: promoDiscount.toFixed(2),
      shipping: shipping.toFixed(2),
      tax: tax.toFixed(2),
      total: totalAfterDiscount.toFixed(2),
    };
  };

  const orderSummary = calculateOrderSummary();

  // Handles promo code validation
  const handleApplyPromo = () => {
    if (promoCode === PROMO_CODE) {
      setPromoDiscount(orderSummary.subtotal * 0.10); // Apply 10% discount
      setPromoError(''); // Clear any previous error
    } else {
      setPromoDiscount(0); // No discount if promo code is invalid
      setPromoError('Promo code is not valid'); // Show error message
    }
  };

  // Display thank you overlay and JSON data
  const handleSubmit = () => {
    setShowOverlay(true); // Show the overlay
    console.log('Form Data:', JSON.stringify({ ...formData, promoDiscount, orderSummary }, null, 2)); // Log JSON with pricing details
  };

  // Handle navigating back to the form to modify the order
  const handleModifyOrder = () => {
    navigate('/', { state: { formData } });
  };

  if (!formData) {
    return <p>No order to review. Please fill out the form first.</p>;
  }

  return (
    <div className="container mt-5">
      <h2 style={{ color: '#3B0A45' }}>Review Your Order</h2>
      <p>Please review your details before submitting:</p>

      <div className="mb-4">
        <strong>Full Name:</strong> {formData.fullName}
      </div>
      <div className="mb-4">
        <strong>Email:</strong> {formData.email}
      </div>
      <div className="mb-4">
        <strong>Phone:</strong> {formData.phone}
      </div>

      <div className="mb-4">
        <strong>Address:</strong> {formData.streetAddress}, {formData.city}, {formData.province}, {formData.postalCode}
      </div>

      <div className="mb-4">
        <strong>Service:</strong> {formData.service} <br />
        <strong>Rush Deadline:</strong> {formData.rushDeadline || 'N/A'}
      </div>

      <div className="mb-4">
        <strong>Delivery + Shipping:</strong> {formData.deliveryShipping}
      </div>

      <div className="mb-4">
        <strong>Items:</strong>
        {formData.items.map((item, index) => (
          <div key={index}>
            {item.material} - Quantity: {item.quantity}, Notes: {item.notes}
          </div>
        ))}
      </div>

      <div className="mb-4">
        <strong>Additional Notes:</strong> {formData.notes || 'None'}
      </div>

      {/* Promo Code Section */}
      <div className="form-group row">
        <label htmlFor="promoCode" className="col-md-2 col-form-label">Promo Code</label>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            id="promoCode"
            name="promoCode"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-outline-primary mt-2"
            onClick={handleApplyPromo}
          >
            Apply Promo
          </button>
        </div>
        {promoError && <small className="text-danger col-md-12">{promoError}</small>}
      </div>

      {/* Order Breakdown */}
      <div className="mt-4 p-4 bg-light rounded">
        <h4>Order Breakdown</h4>
        <p>Subtotal: ${orderSummary.subtotal}</p>
        <p>Promo Discount: -${orderSummary.promoDiscount}</p>
        <p>Shipping: ${orderSummary.shipping}</p>
        <p>Tax: ${orderSummary.tax}</p>
        <h4>Total: ${orderSummary.total}</h4>
      </div>

      {/* Buttons for Submit and Modify Order */}
      <div className="d-flex justify-content-start mt-4">
        <button
          className="btn btn-primary me-3"
          onClick={handleSubmit}
          style={{ backgroundColor: '#FFC107', color: '#3B0A45' }}
        >
          Submit Order
        </button>

        <button
          className="btn btn-secondary"
          onClick={handleModifyOrder}
        >
          Modify Order
        </button>
      </div>

      {/* Thank You Overlay */}
      {showOverlay && (
        <div className="overlay" style={overlayStyles}>
          <div className="overlay-content" style={overlayContentStyles}>
            <h2>Thank You!</h2>
            <p>We have received your quote and will be in touch shortly.</p>

            {/* Display form data as JSON for debugging */}
            <h4>Submitted Data (for debugging):</h4>
            <pre style={jsonStyles}>
              {JSON.stringify({ ...formData, promoDiscount, orderSummary }, null, 2)}
            </pre>

            <button className="btn btn-primary" onClick={() => setShowOverlay(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Styles for the overlay
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

// Styles for the overlay content
const overlayContentStyles = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  width: '80%',
  maxWidth: '500px',
};

// Styles for the JSON data display
const jsonStyles = {
  textAlign: 'left',
  backgroundColor: '#f8f9fa',
  padding: '10px',
  borderRadius: '4px',
  fontSize: '0.9rem',
  maxHeight: '200px',
  overflowY: 'auto',
};

export default ReviewOrder;
