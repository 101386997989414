import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const OrderForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const preFilledData = location.state?.formData || {}; // Get form data passed from ReviewOrder if it exists

  const [formData, setFormData] = useState({
    fullName: preFilledData.fullName || '',
    email: preFilledData.email || '',
    phone: preFilledData.phone || '',
    streetAddress: preFilledData.streetAddress || '',
    city: preFilledData.city || '',
    province: preFilledData.province || '',
    postalCode: preFilledData.postalCode || '',
    service: preFilledData.service || '',
    rushDeadline: preFilledData.rushDeadline || '',
    deliveryShipping: preFilledData.deliveryShipping || '',
    items: preFilledData.items || [{ material: 'VHS', quantity: 1, notes: '' }],
    deliveryOptions: preFilledData.deliveryOptions || {
      cloud: false,
      hardDrive: false,
      usb: false,
      dvd: false,
      sdCard: false,
      cloudStorage: false,
    },
    notes: preFilledData.notes || '',
    authorization: preFilledData.authorization || false,
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        deliveryOptions: { ...formData.deliveryOptions, [name]: checked },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = formData.items.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData({ ...formData, items: updatedItems });
  };

  const addItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, { material: 'VHS', quantity: 1, notes: '' }],
    });
  };

  const removeItem = () => {
    if (formData.items.length > 1) {
      setFormData({
        ...formData,
        items: formData.items.slice(0, -1),
      });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = "Full Name is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone) errors.phone = "Phone number is required.";
    if (!formData.streetAddress) errors.streetAddress = "Street Address is required.";
    if (!formData.city) errors.city = "City is required.";
    if (!formData.province) errors.province = "Please select a province.";
    if (formData.items.length === 0 || formData.items.every(item => !item.material)) {
      errors.items = "At least one material must be selected.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleReviewOrder = (e) => {
    e.preventDefault();
    if (validateForm()) {
      navigate('/review-order', { state: { formData } });
    }
  };

  return (
    <div className="container mt-5">
      <h2 style={{ color: '#3B0A45' }}>Get a Quote</h2>
      <p className="lead">
        Please fill out the form below to get an estimate. Once you click "Review Order", you will be taken to the next page to verify your selections.
      </p>
      <form>
        {/* Personal Information Fields */}
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
              {formErrors.fullName && <small className="text-danger">{formErrors.fullName}</small>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {formErrors.email && <small className="text-danger">{formErrors.email}</small>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              {formErrors.phone && <small className="text-danger">{formErrors.phone}</small>}
            </div>
          </div>
        </div>

        <hr className="my-4" />

        {/* Address Fields */}
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="streetAddress">Street Address</label>
              <input
                type="text"
                className="form-control"
                id="streetAddress"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleInputChange}
                required
              />
              {formErrors.streetAddress && <small className="text-danger">{formErrors.streetAddress}</small>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
              {formErrors.city && <small className="text-danger">{formErrors.city}</small>}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="province">Province</label>
              <select
                id="province"
                name="province"
                className="form-control"
                value={formData.province}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select Province
                </option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
              </select>
              {formErrors.province && <small className="text-danger">{formErrors.province}</small>}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                className="form-control"
                id="postalCode"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>

        <hr className="my-4" />

        {/* Items to be converted */}
        <div className="form-group">
          <label>Items to be converted (input)</label>
          {formData.items.map((item, index) => (
            <div key={index} className="row mb-3">
              <div className="form-group col-md-4">
                <label htmlFor={`material${index}`}>Material</label>
                <select
                  id={`material${index}`}
                  name="material"
                  className="form-control"
                  value={item.material}
                  onChange={(e) => handleItemChange(index, e)}
                >
                  <option value="VHS">VHS - $20.00 per tape (up to 120 minutes)</option>
                  <option value="VHSc">VHSc - $20.00 per tape (up to 120 minutes)</option>
                  <option value="HDV">HDV - $20.00 per tape (up to 120 minutes)</option>
                  <option value="MiniDV">MiniDV - $20.00 per tape (up to 120 minutes)</option>
                  <option value="Hi8">Hi8 - $20.00 per tape (up to 120 minutes)</option>
                  <option value="Digital 8">Digital 8 - $20.00 per tape (up to 120 minutes)</option>
                  <option value="8MM">8MM - $20.00 per tape (up to 120 minutes)</option>
                  <option value="DV">DV - $20.00 per tape (up to 120 minutes)</option>
                  <option value="DVcam">DVcam - $20.00 per tape (up to 120 minutes)</option>
                  <option value="DVD">DVD - $15.00 per DVD</option>
                  <option value="Beta">Beta - $40.00 per tape (up to 120 minutes)</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`quantity${index}`}>Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  id={`quantity${index}`}
                  name="quantity"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, e)}
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor={`notes${index}`}>Condition/Notes</label>
                <input
                  type="text"
                  className="form-control"
                  id={`notes${index}`}
                  name="notes"
                  value={item.notes}
                  onChange={(e) => handleItemChange(index, e)}
                />
              </div>
            </div>
          ))}
          {formErrors.items && <small className="text-danger">{formErrors.items}</small>}
          <button
            type="button"
            className="btn btn-secondary mt-2"
            onClick={addItem}
            style={{ backgroundColor: '#3B0A45', color: '#fff' }}
          >
            Add another item
          </button>
          <button
            type="button"
            className="btn btn-danger mt-2"
            onClick={removeItem}
            style={{ backgroundColor: '#EB6B56', color: '#fff' }}
          >
            Delete item
          </button>
        </div>

        {/* Review Order Button */}
        <button
          type="button"
          className="btn btn-info mt-3"
          onClick={handleReviewOrder}
          style={{ backgroundColor: '#FFC107', color: '#3B0A45' }}
        >
          Review Order
        </button>
      </form>
    </div>
  );
};

export default OrderForm;
